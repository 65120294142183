<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Power Discos</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Power</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Discos</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                  
                    <div class="col-1"></div>
                    <select
                      class="form-control col-2"
                      v-model="vendor"
                      @change.prevent="getdiscos()"
                    >
                      <option value="all">All Vendors</option>
                      <option v-for="(vendor,index) in vendors" :key="index">{{ vendor }}</option>
                    </select>
                  </div>

                  <marquee
                    class="text-danger"
                  >WARNING!!! Actions carried out here are dangerous and can lead to system failure.</marquee>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Vendor</th>
                        <th>Prepaid Service Charge</th>
                        <th>Postpaid Service Charge</th>
                        <th>Minimum Amount</th>
                        <th>Maximum Amount</th>
                    
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="discos.length < 1">No disco found</span>
                      <tr v-for="(disco,index) in discos" :key="index">
                        <td>{{disco?.name}}</td>
                        <td>{{disco?.service_provider}}</td>
                        <td>{{disco?.prepaid_service_charge | currency}}</td>
                        <td>{{disco?.postpaid_service_charge | currency}}</td>
                        <td>{{disco?.minimum_amount | currency }}</td>
                        <td>{{disco?.maximum_amount | currency }}</td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; displayForm(disco)"
                          >&#9998;</button>
                        
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      discos: [],
      vendors: [],
      editing: false,
      vendor: "all",
      currentPage: 1
    };
  },
  components: {},
  mounted() {
    this.getdiscos();
    this.getVendors();
  },
  methods: {
    getdiscos() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "power/discos/" +
          this.vendor +
          "/all?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.discos = result.data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
   
    async displayForm(disco) {
      let discoName = "";
      let discoVendor = "";
      let prepaidServiceCharge = "";
      let postpaidSeviceCharge = "";
      let discoMinimumAmount = "";
      let discoMaximumAmount = "";

      if (typeof disco !== "undefined") {
        discoName = disco.name;
   
        discoVendor = disco.service_provider;
      
        prepaidServiceCharge = disco.prepaid_service_charge;
        postpaidSeviceCharge = disco.postpaid_service_charge;
        
        discoMinimumAmount = disco.minimum_amount;
        discoMaximumAmount = disco.maximum_amount;
      }
      let vendors = disco.service_options.map(vendor => {
        return (
          '<option value="' +
          vendor +
          '" selected="' +
          (discoVendor == vendor ? "selected" : "") +
          '">' +
          this.capitalize(vendor) +
          "</option>"
        );
      });

      const { value: formValues } = await Swal.fire({
        title: this.editing ? "Edit disco" : "Create disco",
        html:
          '<div class="form-group">' +
          "<label>Disco Name</label>" +
          '<input id="name" type="text" placeholder="Disco Name" value="' +
          discoName +
          '" class="swal2-input" autocomplete="off">' +
          "</div>" +
         
         
          '<div class="form-group">' +
          "<label>Prepaid Service Charge (NGN)</label>" +
          '<input id="prepaid_service_charge" type="text" placeholder="Prepaid Service Charge" value="' +
          prepaidServiceCharge +
          '"  class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          "<label>Postpaid Service Charge (NGN)</label>" +
          '<input id="postpaid_service_charge" type="text" placeholder="Postpaid service charge" value="' +
          postpaidSeviceCharge +
          '"  class="swal2-input" autocomplete="off">' +
          "</div>" +
          
          
          '<div class="form-group">' +
          "<label>Minimum Amount (NGN)</label>" +
          '<input id="minimum_amount" type="text" placeholder="Minimum Amount" value="' +
          discoMinimumAmount +
          '"  class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          "<label>Maximum Amount (NGN)</label>" +
          '<input id="maximum_amount" type="text" placeholder="maximum amount" value="' +
          discoMaximumAmount +
          '"  class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<select id="service_provider" class="swal2-input" >' +
          '<option value="">Select Vendor</value>' +
          vendors +
          "</select>" +
          "</div>",
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            name: document.getElementById("name").value,
           
            prepaid_service_charge: document.getElementById(
              "prepaid_service_charge"
            ).value,
            postpaid_service_charge: document.getElementById(
              "postpaid_service_charge"
            ).value,
            
            minimum_amount: document.getElementById("minimum_amount").value,
            maximum_amount: document.getElementById("maximum_amount").value,
            service_provider: document.getElementById("service_provider").value
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        if (this.editing) {
          this.editdisco(formValues, disco.id);
        } else {
          this.createdisco(formValues);
        }
      }
    },

    editdisco(formValues, id) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "power/discos/" + id + "/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(formValues)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let discoIndex = this.discos.findIndex(disco => {
              if (disco.id == id) return true;
            });
            this.discos[discoIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

     getVendors() {
      fetch(this.$store.getters.baseURL + "power/discos/providers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.vendors = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

 
  }
};
</script>

<style scoped>
</style>