<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Networks</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">CableTv</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Bouquet Type</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                 
                    <div class="col-1"></div>
                      <select class="form-control col-2" v-model="vendor" @change.prevent="getbouquetTypes()">
                      <option value="all">All Vendors</option>
                      <option v-for="(vendor,index) in vendors" :key="index" :value="vendor">{{ vendor }}</option>
                    </select>
                  </div>
                
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th width="33%;">Name</th>
                        <th width="33%;">Vendor</th>
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="bouquetTypes.length < 1">No bouquetType found</span>
                      <tr v-for="(bouquetType,index) in bouquetTypes" :key="index">
                        <td>{{bouquetType?.name}}</td>
                        <td>{{bouquetType?.vendor}}</td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; displayForm(bouquetType)"
                          >&#9998;</button>
                          
                           <router-link
                            class="btn btn-primary"
                            :to="'/data_bundles/'+bouquetType?.id"
                          >View Bundles</router-link> 
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      bouquetTypes: [],
      vendors: [],
      editing: false,
      vendor: "all",
    };
  },
  components: {

  },
  mounted() {
    this.getbouquetTypes();
    this.getVendors();
  },
  methods: {
    getbouquetTypes() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "data/networks/"+this.vendor+"/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.bouquetTypes = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getVendors() {
      fetch(this.$store.getters.baseURL + "data/networks/providers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.vendors = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    async displayForm(bouquet) {
      let bouquetName = "";
      let bouquetVendor = "";

      if(typeof bouquet !== 'undefined') {
        bouquetName = bouquet.name;
        bouquetVendor = bouquet.vendor;
       
      }
      let vendors = this.vendors.map(vendor => {
        return '<option value="' + vendor + '" selected="'+ ( (bouquetVendor == vendor)? "selected" : "") +'">' + this.capitalize(vendor) + "</option>";
      });

     

    

      const { value: formValues } = await Swal.fire({
        title: (this.editing)? "Edit bouquet" : "Create bouquet",
        html:
          '<div class="form-group">' +
          '<input id="name" type="text" placeholder="Name" value="'+ bouquetName+'" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<select id="vendor" class="swal2-input" >' +
          '<option value="">Select Vendor</value>' +
          vendors +
          "</select>" +
          "</div>",
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            name: document.getElementById("name").value,
            vendor: document.getElementById("vendor").value
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        if(this.editing) {

          this.editBouquet(formValues,bouquet.id);
        }
      }
    },

    editBouquet(formValues,id) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "data/networks/"+id+"/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(formValues)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
       
            let bouquetTypeIndex = this.bouquetTypes.findIndex(bouquetType => {
              if(bouquetType.id == id) return true;
            });
            this.bouquetTypes[bouquetTypeIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
     
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });


    },

    



    
  }
};
</script>

<style scoped>
</style>