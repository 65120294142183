<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>System Users</h1>
              </div>

              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Tables</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">System Users</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <div class="col-1"></div>
                     <button @click="displayForm()" class="btn btn-secondary col-2">Create New</button>
                    <div class="col-1"></div>
                    <select v-model="role" @change="searchUserByRole()" class="form-control col-2">
                      <option value="all">All Users</option>
                      <option
                        v-for="role in roles"
                        :key="role.name"
                        :value="role.name"
                      >{{ role.name | capitalize }}</option>
                    </select>

                    <div class="col-2"></div>

                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchUsers()"
                        placeholder="search"
                        class="form-control"
                      />
                      
                    </div>
                    <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>

                   
                  </div>

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Img</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th>Balance</th>
                        <th>Date</th>
                        <th>Roles</th>
                        <th colspan="2">Actions</th>
                      </tr>
                    </thead>
                    <span v-if="users?.length < 1">No user Available</span>
                    <tbody v-else>
                      <tr v-for="user in users" :key="user?.id">
                        <td>
                          <img
                            :src="user?.profile_image"
                            class="profile-image"
                            style="width: 50px; height: 50px;"
                            alt="image"
                          />
                        </td>
                        <td>{{user?.first_name}}</td>
                        <td>{{user?.last_name}}</td>
                        <td>{{user?.email}}</td>
                        <td>{{user?.phone_number}}</td>
                        <td>{{(user?.active)? "active" : "deactivated" }}</td>
                        <td>{{user?.wallet?.balance | currency}}</td>
                        <td>{{user?.created_at}}</td>
                        <td>
                          <span v-for="(role,index) in user?.roles" :key="index">{{role.name}}</span>,
                        </td>

                        <td v-if="iLoading(user?.id)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                        <td v-else>
                          <button
                            v-if="user?.active"
                            @click="toggleActiveStatus(user?.id)"
                            class="btn btn-danger"
                          >Deactivate</button>
                          <button
                            v-else
                            @click="toggleActiveStatus(user?.id)"
                            class="btn btn-primary"
                          >Activate</button>
                        </td>
                        <td>
                          <router-link :to="'/profile/'+user?.id" class="btn btn-primary">Detail</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <sliding-pagination
                    v-if="users?.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

// import Paginate from 'vuejs-paginate'

export default {
  components: {
    
 
  },
  data() {
    return {
      currentPage: 1,
      roles: [],
      users: [],
      keyword: "",
      role: "all",
      bin: [],
      loadingBtn: [],
      totalPages: 1,
      interval: null
    };
  },
  computed: {},
  mounted() {
    this.getRoles();
    this.searchUserByRole();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.searchUserByRole();
    },
    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    },

    getRoles() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "role_management/all_roles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.roles = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    searchUsers() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.searchUserByRole();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "users_management/" +
          encodeURI(this.keyword) +
          "/sort_users",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.users = result.data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    searchUserByRole() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "users_management/" +
          this.role +
          "/users_role?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.users = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    toggleActiveStatus(id) {
      this.loadingBtn.push(id);
      fetch(
        this.$store.getters.baseURL + "users_management/" + id + "/active",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let userID = this.users.findIndex((user) => {
              if (user?.id == result.data.id) return true;
            });

            this.users.splice(userID,1,result.data);
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    async displayForm() {
      let rolesOpt = this.roles.map(role => {
        return '<option value="' + role.name + '">' + role.name + "</option>";
      });
      const { value: formValues } = await Swal.fire({
        title: "Create User",
        html:
          '<div class="form-group">' +
          '<input id="first_name" type="text" placeholder="First Name" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="last_name" type="text" placeholder="Last Name" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="email" type="email" placeholder="Email" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="phone_number" class="swal2-input" placeholder="Phone Number" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="password" type="password" placeholder="Password" class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<input id="password_confirmation" type="password" placeholder="Confirm Password"  class="swal2-input" autocomplete="off">' +
          "</div>" +
          '<div class="form-group">' +
          '<select id="role"  class="swal2-input" >' +
          '<option value="">Select Role</value>' +
          rolesOpt +
          "</select>" +
          "</div>",
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            first_name: document.getElementById("first_name").value,
            last_name: document.getElementById("last_name").value,
            email: document.getElementById("email").value,
            phone_number: document.getElementById("phone_number").value,
            password: document.getElementById("password").value,
            password_confirmation: document.getElementById(
              "password_confirmation"
            ).value,
            role: document.getElementById("role").value
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        this.createUser(formValues);
      }
    },

    createUser(data) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "users/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let data = result.data;
            this.users = {data,...this.users};
            Swal.fire({
              icon: "success",
              title: result.message
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>

</style>