<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Goals</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Goals</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Goals</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchTransactions()"
                        placeholder="search"
                      />
                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Id</th>
                        <th>User</th>
                        <th>Slug</th>
                        <th>Name</th>
                        <th>Target Amount</th>
                        <th>Current Amount</th>
                        <th>Maturity Date</th>
                        <th>Status</th>
                        <th>Updated at</th>
                        <th>Created at</th>
                        <th>transactions</th>
                        <th>rules</th>
                      </tr>
                    </thead>
                    <span v-if="goals?.length < 1">No goal available</span>
                    <tbody v-else>
                      <tr v-for="(goal,index) in goals" :key="index">
                        <td>{{ goal?.id }}</td>
                        <td>
                          <router-link v-if="goal?.user"
                            :to="'/profile/'+goal?.user?.id"
                          >{{ goal?.user?.first_name | capitalize }} {{ goal?.user?.last_name | capitalize }}</router-link>
                        </td>
                        <td>{{ goal?.slug }}</td>
                        <td>{{ goal?.name }}</td>
                        <td>{{ goal?.target_amount | currency }}</td>
                        <td>{{ goal?.current_amount | currency }}</td>
                        <td>{{ goal?.maturity_date }}</td>
                        <td>{{ goal?.status }}</td>
                        <td>{{ goal?.updated_at | formatDate }}</td>
                        <td>{{ goal?.created_at | formatDate }}</td>
                        <td>
                          <table>
                            <tr>
                              <th>Amount</th>
                              <th>type</th>
                              <th>description</th>
                              <th>created at</th>
                            </tr>
                            <span v-if="goal?.transactions?.length < 1">No Transaction</span>
                            <tr v-for="(transaction,index) in goal?.transactions" :key="index">
                              <td>{{transaction?.amount | currency}}</td>
                              <td>{{transaction?.type}}</td>
                              <td>{{transaction?.description}}</td>
                              <td>{{transaction?.created_at | formatDate}}</td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <table>
                            <tr>
                              <th>Account Name</th>
                              <th>Account Number</th>
                              <th>Bank Name</th>
                              <th>Bank Code</th>
                              <th>Percentage</th>
                            </tr>
                            <template v-for="(rule,index) in goal?.rules" >
                              <tr v-for="(disbursement_detail,indexs) in rule?.disbursement_details" :key="indexs + index">
                              <td>{{disbursement_detail?.account_name }}</td>
                              <td>{{disbursement_detail?.account_number}}</td>
                              <td>{{disbursement_detail?.bank_name}}</td>
                              <td>{{disbursement_detail?.bank_code }}</td>
                              <td>{{disbursement_detail?.percentage }}%</td>
                            </tr>
                            </template>
                         
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="goals?.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";

export default {
  data() {
    return {
      goals: [], // List of goals from API
      currentPage: 1,
      totalPages: 1,
      interval: null,
      keyword: ""
    
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.getGoals(); // Fetch goals on mount
  },
  methods: {
    getGoals() {
      this.$store.commit("setLoading", true);
      fetch(`${this.$store.getters.baseURL}goal_management?page=${this.currentPage}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status) {
            this.goals = result.data.data; // Update goals list
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message,
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Check your connection",
          });
          this.$store.commit("setLoading", false);
          console.log(err)
        });
    },
    pageChangeHandler(page) {
      this.currentPage = page;
      this.getGoals(); // Fetch goals for new page
    },
  },
};
</script>

<style scoped>
.goals-container {
  padding: 20px;
}
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.goal-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  margin: 0 10px;
  padding: 5px 10px;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
