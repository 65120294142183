import { render, staticRenderFns } from "./WalletWalletTransaction.vue?vue&type=template&id=79653103&scoped=true"
import script from "./WalletWalletTransaction.vue?vue&type=script&lang=js"
export * from "./WalletWalletTransaction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79653103",
  null
  
)

export default component.exports