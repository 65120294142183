<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Stores</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Stores</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Store</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFile1"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editStore.name" />
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="editStore.email" />
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="editStore.description" />
                  </div>
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" v-model="editStore.address" />
                  </div>
                  <div class="form-group">
                    <label>Link</label>
                    <input type="url" class="form-control" v-model="editStore.link" />
                  </div>
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editStore.phone_number" />
                  </div>
                  <div class="form-group">
                    <label>Service Charge</label>
                    <input type="text" class="form-control" v-model="editStore.service_charge" />
                  </div>
                  <div class="form-group">
                    <label>Time</label>
                    <input type="text" class="form-control" v-model="editStore.time" />
                  </div>
                  <div class="form-group">
                    <label>Vat</label>
                    <input type="number" class="form-control" v-model="editStore.vat" />
                  </div>
                  <div class="form-group">
                    <label>State</label>
                    <select class="form-control" v-model="editStore.state_id">
                      <option>Select State</option>
                      <option
                        :value="state.id"
                        v-for="(state,index) in states"
                        :key="index"
                        :selected="(state.id == editStore.state_id)? 'selected' : ''"
                      >{{state.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="createStore();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Store</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editStore.name" />
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="editStore.email" />
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="editStore.description" />
                  </div>
                  <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" v-model="editStore.address" />
                  </div>
                  <div class="form-group">
                    <label>Link</label>
                    <input type="url" class="form-control" v-model="editStore.link" />
                  </div>
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editStore.phone_number" />
                  </div>
                  <div class="form-group">
                    <label>Service Charge</label>
                    <input type="text" class="form-control" v-model="editStore.service_charge" />
                  </div>
                  <div class="form-group">
                    <label>Time</label>
                    <input type="text" class="form-control" v-model="editStore.time" />
                  </div>
                  <div class="form-group">
                    <label>Vat</label>
                    <input type="number" class="form-control" v-model="editStore.vat" />
                  </div>
                  <div class="form-group">
                    <label>State</label>
                    <select class="form-control" v-model="editStore.state_id">
                      <option>Select State</option>
                      <option
                        :value="state.id"
                        v-for="(state,index) in states"
                        :key="index"
                        :selected="(state.id == editStore.state_id)? 'selected' : ''"
                      >{{state.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="editstore();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      @click.prevent="editStore = {}"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Stores</a>
                    <div class="col-1"></div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Description</th>
                        <th>Address</th>
                        <th>Link</th>
                        <th>Phone Number</th>
                        <th>Service Charge</th>
                        <th>State</th>
                        <th>Time</th>
                        <th>VAT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="stores.length < 1">No Store Found</span>
                      <tr v-for="(store,index) in stores" :key="index">
                        <td>
                          <img
                            :src="store.image"
                            class="profile-image"
                            style="width: 50px; height: 50px;"
                            alt="LOGO"
                          />
                        </td>
                        <td>{{store.name}}</td>
                        <td>{{store.email}}</td>
                        <td>{{store.description}}</td>
                        <td>{{store.address }}</td>
                        <td>
                          <a :href="store.link" target="_blank">{{store.link}}</a>
                        </td>
                        <td>{{store.phone_number}}</td>
                        <td>{{store.service_charge }}</td>
                        <td>{{store.state.name }}</td>
                        <td>{{store.time }}</td>
                        <td>{{store.vat}}%</td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(store)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deletestore(store.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      stores: [],
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editStore: {}
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getstores();
    this.getStates();
  },
  methods: {
    encodeFile(e) {

      let file = e.target.files[0];
     
      if(!file) return;
      let reader = new FileReader();
      reader.onloadend = () => {
        this.editStore.image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    prepEdit(store) {
      this.editStore = store;
      this.editStore.image = null;
    },
    getstores() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "store/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.stores = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    deletestore(id, index) {
      const link =
        this.$store.getters.baseURL + "store/" + id + "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete store?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "sucreadAsDataURLcess",
                    title: result.message
                  });
                  this.stores.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },
    getStates() {
      fetch(this.$store.getters.baseURL + "states/admin/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.states = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    editstore() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "store/" +
          this.editStore.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editStore)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let storeIndex = this.stores.findIndex(store => {
              if (store.id == this.editStore.id) return true;
            });
            this.stores[storeIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    createStore() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "store/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.editStore)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.stores = [result.data, ...this.stores];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped></style>
