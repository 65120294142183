<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Roles Management</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">
                        <i class="ti ti-home"></i>
                      </router-link>
                    </li>
                    <li class="breadcrumb-item">Roles Management</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page"></li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="row">
                  <div v-if="permissions.length > 0" class="card col-12 col-md-11 ml-auto mr-auto">
                    <button @click="createRoleForm()" class="btn btn-success "  style="border-radius: 0 50px;">Create Role</button>
                    <h2 class="card-header text-center">Permission Set</h2>
                    <ul class="card-body row" style="list-style: none; ">
                      <li
                        v-for="(permission,permissionIndex) in permissions"
                        :key="permissionIndex"
                        class="text-dark mb-3  col-10	col-sm-6	col-md-5	col-lg-4	col-xl-3"
                      >{{ permission.name | capitalize }}</li>
                    </ul>
                  </div>
                  <div
                    class="card col-md-5 col-12 mr-auto ml-auto"
                    v-for="(role,roleIndex) in roles"
                    :key="roleIndex"
                  >
                    <h2 class="card-header text-center">{{role.name | capitalize }}</h2>

                    <ul class="card-body row" style="list-style: none;">
                      <li
                        class="text-dark col-md-6 col-12"
                        v-for="(permission,permissionIndex) in role.permissions"
                        :key="permissionIndex"
                      >
                        {{permission.name | capitalize}}
                        <span
                          @click="togglePermission(roleIndex,permissionIndex,0)"
                          class="text-danger ml-2"
                          style="font-size: 12px; cursor: pointer;"
                        >X</span>
                      </li>
                    </ul>
                    <div class="card-footer row">
                      <button
                        @click="deleteRole(roleIndex)"
                        class="btn btn-danger mr-auto"
                        style="border-radius: 20px 0;"
                      >Delete Role</button>
                      <button
                        @click="addPermission(roleIndex)"
                        class="btn btn-primary ml-auto"
                        style="border-radius: 0 20px;"
                      >Add Permissions</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  components: {
  
  },
  data() {
    return {
      roles: [],
      permissions: [],
      bin: []
    };
  },
  mounted() {
     this.getPermissions();
    this.getRolesWithPermissions();
   
  },
  methods: {
    addPermission(roleIndex) {
      this.bin.push(roleIndex);

      let permissions = this.permissions.filter(n => {
        let index = this.roles[roleIndex].permissions.findIndex(item => {
          if (item.name == n.name) return true;
        });
        if (index < 0) return true;
      });
      permissions = permissions.map(({ name }) => name);

      if (permissions.length < 1) return;

      Swal.fire({
        title: "Select Permission",
        input: "select",
        inputOptions: permissions,
        inputPlaceholder: "Select Permissions",
        showCancelButton: true,
        inputValidator: value => {
          if (!value) return;
          let permissionIndex = this.permissions.findIndex(item => {
            if (item.name == permissions[value]) return true;
          });

          this.togglePermission(roleIndex, permissionIndex, 1);
        }
      });
    },
    togglePermission(roleIndex, permissionIndex, assign) {
      let permissionName =
        assign == 1
          ? this.permissions[permissionIndex].name
          : this.roles[roleIndex].permissions[permissionIndex].name;
      fetch(this.$store.getters.baseURL + "role_management/toggle_permission", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify({
          assign: assign,
          permission: permissionName,
          role: this.roles[roleIndex].name
        })
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            if (assign == 0) {
              this.roles[roleIndex].permissions.splice(permissionIndex, 1);
            } else {
              this.roles[roleIndex].permissions.push(
                this.permissions[permissionIndex]
              );
            }
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getRolesWithPermissions() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "role_management/all_roles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.roles = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getPermissions() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "role_management/all_permissions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.permissions = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    deleteRole(roleIndex) {
      const link = this.$store.getters.baseURL + "role_management/delete_role";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete Role?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              },
              body: JSON.stringify({
                name: this.roles[roleIndex].name
              })
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.roles.splice(roleIndex,1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },
    createRoleForm() {
      Swal.fire({
        title: 'Enter Role Name',
        input: 'text',
        inputValue: "",
        showCancelButton: true,
        inputValidator: (value) => {
            if(!value) {
              Swal.fire({
                icon: "error",
                title: "Invalid input"
              });
            }else {
              this.createRole(value);
            }
          
        }
      });
    },
    createRole(name) {
       this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "role_management/create_role", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify({
          "name": name
        })
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            Swal.fire({
              icon: "success",
              title: result.message
            });
           this.roles.push({...result.data,permissions: []});
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>
</style>