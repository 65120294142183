<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Data Bundles</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Data</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Bundles</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>code</th>
                        <th>Price</th>
                        <th>Validity</th>
                        <th>Code</th>
                        
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="bundles.length < 1">No bundle found</span>
                      <tr v-for="(bouquet,index) in bundles" :key="index">
                        <td>{{bouquet?.title}}</td>
                        <td>{{bouquet?.code}}</td>
                         <td>{{bouquet?.price | currency }}</td>
                          <td>{{bouquet?.validity }}</td>
                          <td>{{ bouquet?.code }}</td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; displayForm(bouquet)"
                          >&#9998;</button>
                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      typeId: this.$route.params.id,
      bundles: [],
      editing: false
    };
  },
  components: {

  },
  mounted() {
    this.getbundles();
  },
  methods: {
    getbundles() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "data/bundles/"+this.typeId+"/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
      .then(res => res.json())
      .then(result => {
        console.log(result);
        if (result.status) {
          this.bundles = result.data;
        } else {
          Swal.fire({
            icon: "error",
            title: result.message
          });
        }
        this.$store.commit("setLoading", false);
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Check your connection"
        });
        this.$store.commit("setLoading", false);
      });
    },
 
  
    async displayForm(bouquet) {

      let bundleName = bouquet.title;
      let price = bouquet.price;
      let code = bouquet.code;
      let validity = bouquet.validity;
     
      if(typeof bouquet !== 'undefined') {
        bundleName = bouquet.title;
        price =   bouquet.price;
        code = bouquet.code;
        validity = bouquet.validity;
      }

      const { value: formValues } = await Swal.fire({
        title: (this.editing)? "Edit bouquet" : "Create bouquet",
        html:
          '<div class="form-group">' +
            '<input id="name" type="text" placeholder="Bundle Name" value="'+ bundleName+'" class="swal2-input" autocomplete="off">' +
          "</div>"+

          '<div class="form-group">' +
            '<input id="code" type="text" placeholder="Code" value="'+ code+'" class="swal2-input" autocomplete="off">' +
          "</div>"+

          '<div class="form-group">' +
            '<input id="validity" type="text" placeholder="Validity" value="'+ validity+'" class="swal2-input" autocomplete="off">' +
          "</div>"+
       
          '<div class="form-group">' +
            '<input id="price" type="text" placeholder="Bundle Price" value="'+ price+'" class="swal2-input" autocomplete="off">' +
          "</div>",
        focusConfirm: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        preConfirm: () => {
          return {
            title: document.getElementById("name").value,
            price: document.getElementById("price").value,
            validity: document.getElementById("validity").value,
            code: document.getElementById("code").value
          };
        }
      });

      if (formValues) {
        console.log(formValues);
        if(this.editing) {

          this.editBouquet(formValues,bouquet.id);
        }
      }
    },

    editBouquet(formValues,id) {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "data/bundles/"+id+"/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify({
          "cable_tv_type_id": this.typeId,
          ...formValues
        })
      })
      .then(res => res.json())
      .then(result => {
        console.log(result);
        if (result.status) {
      
          let bouquetIndex = this.bundles.findIndex(bouquet => {
            if(bouquet.id == id) return true;
          });
          this.bundles[bouquetIndex] = result.data;
        } else {
          Swal.fire({
            icon: "error",
            title: result.message
          });
        }
        this.$store.commit("setLoading", false);
      })
      .catch(() => {
    
        Swal.fire({
          icon: "error",
          title: "Check your connection"
        });
        this.$store.commit("setLoading", false);
      });


    },
  }
};
</script>

<style scoped>
</style>