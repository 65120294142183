<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Routes</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Dispatch</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Routes</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Route</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Dispatch</label>
                    <select
                      class="form-control"
                      @change="getLocations"
                      v-model="editRoute.from_location_id"
                    >
                      <option :value="'undefined'">Select Dispatch</option>
                      <option
                        :value="dispatch.id"
                        v-for="(dispatch,index) in dispatchs"
                        :key="index"
                        :selected="(dispatch.id == editRoute.from_location_id)? 'selected' : ''"
                      >{{dispatch.name}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Location</label>
                    <select class="form-control" v-model="editRoute.to_location_id">
                      <option :value="'undefined'">Select Location</option>
                      <option v-if="locations.length < 1">No Location</option>
                      <option
                        :value="location.id"
                        v-for="(location,index) in locations"
                        :key="index"
                        :selected="(location.id == editRoute.to_location_id)? 'selected' : ''"
                      >{{location.name}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Estimated Time</label>
                    <input type="text" class="form-control" v-model="editRoute.estimated_time" />
                  </div>

                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text" class="form-control" v-model="editRoute.amount" />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="createRoute();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Route</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Estimated Time</label>
                    <input type="text" class="form-control" v-model="editRoute.estimated_time" />
                  </div>

                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text" class="form-control" v-model="editRoute.amount" />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="editroute();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      @click.prevent="editRoute = {}"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Routes</a>
                    <div class="col-1"></div>
                    <div class="col-3">
                      <select
                        v-model="fromLocationId"
                        class="form-control"
                        @change="getRoutes(); currentPage=1"
                      >
                        <option value="all">All Dispatch</option>
                        <option
                          v-for="(dispatch,index) in dispatchs"
                          :key="index"
                          :value="dispatch.id"
                        >{{ dispatch.name }}</option>
                      </select>
                    </div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>From Location</th>
                        <th>To Location</th>
                        <th>Extimated Time</th>
                        <th>Amount</th>
                        <th class="text-center" colspan="2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="routes.length < 1">No Route Found</span>
                      <tr v-for="(route,index) in routes" :key="index">
                        <td>{{ route.from_location? route.from_location.name : "" }}</td>
                        <td>{{ route.to_location? route.to_location.name : "" }}</td>
                        <td>{{ route.estimated_time }}</td>
                        <td>{{ route.amount | currency }}</td>

                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(route)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleteroute(route.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="routes.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      routes: [],
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editRoute: {},
      fromLocationId: "all",
      locationId: "all",
      dispatchs: [],
      locations: [],
      totalPages: 1
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getRoutes();
    this.getFromLocations();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      this.getRoutes();
    },
    prepEdit(route) {
      this.editRoute = { ...route };
      this.editRoute.image = null;
    },
    getRoutes() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "dispatchs/routes/" +
          this.fromLocationId +
          "/" +
          this.locationId +
          "/all?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.routes = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    deleteroute(id, index) {
      const link =
        this.$store.getters.baseURL + "dispatchs/routes/" + id + "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete route?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.routes.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },

    getFromLocations() {
      fetch(this.$store.getters.baseURL + "dispatchs/routes/active_locations", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.dispatchs = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    getLocations() {
      fetch(
        this.$store.getters.baseURL +
          "dispatchs/routes/" +
          this.editRoute.from_location_id +
          "/unassigned_locations",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.locations = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    editroute() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "dispatchs/routes/" +
          this.editRoute.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editRoute)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let routeIndex = this.routes.findIndex(route => {
              if (route.id == this.editRoute.id) return true;
            });
            this.routes[routeIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    createRoute() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "dispatchs/routes/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.editRoute)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.routes = [result.data, ...this.routes];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped></style>
