<template>
  <div class="app-container">
    <div
      v-if="order != null"
      class="modal fade bd-example-modal-lg"
      id="viewMore"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewMoreLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Order Info</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="text-primary">Order Details</h4>
            <table class="table">
              <tr>
                <th>Order No</th>
                <td>{{ order.order_no }}</td>
                <th>Cost</th>
                <td>{{ order.total_cost | currency }}</td>
              </tr>
              <tr>
                <th>Order Status</th>
                <td>{{ order.order_status  | capitalize }}</td>
                <th>
                  <select v-model="order.order_status" class="form-control">
                    <option
                      v-for="(status,index) in allStatus"
                      :key="index"
                      :value="status"
                    >{{ status | capitalize }}</option>
                  </select>
                </th>
                <td>
                  <template v-if="(orders[index].order_status != order.order_status)">
                    <button @click="updateStatus" v-if="!loading" class="btn btn-primary">Update</button>
                    <div v-else class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </template>
                </td>
              </tr>
              <tr v-if="!order.movie.free">
                <th>Payment Refernce</th>
                <td>{{ order.payment_reference }}</td>
                <th>Payment Method</th>
                <td>{{ order.payment_method }}</td>
              </tr>
              <tr v-if="!order.movie.free">
                <th>Payment Status</th>
                <td>{{ order.payment_status }}</td>

                <td colspan="2">
                  <template v-if="(order.payment_status != 'paid')">
                    <button v-if="!loading" class="btn btn-danger" @click="payOrder">PAY</button>
                    <div class="spinner-border" v-else role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </template>
                </td>
              </tr>

              <tr>
                <th>Created</th>
                <td>{{ order.created_at | formatDate }}</td>
                <th>Last Updated</th>
                <td>{{ order.updated_at | formatDate }}</td>
              </tr>
            </table>

            <template v-if="order.tickets.length > 0">
              <h4 class="text-primary">Tickets</h4>
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Unit Cost</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ticket,index) in order.tickets" :key="index">
                    <td>{{ ticket.ticket.name }}</td>

                    <td>{{ ticket.quantity }}</td>
                    <td>{{ ticket.ticket.cost | currency }}</td>

                    <td>{{ ticket.quantity * ticket.ticket.cost | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </template>

            <h4 class="text-primary">Schedule Details</h4>
            <table class="table">
              <tr>
                <th>{{ order.schedule.time.day }} {{ order.schedule.time.date }} {{ order.schedule.time.time }}</th>
              </tr>
            </table>

            <h4 class="text-primary">Customer Details</h4>
            <table class="table">
              <tr>
                <th>Name</th>
                <td>{{ order.user.first_name | capitalize }} {{ order.user.last_name | capitalize }}</td>
                <th>Phone Number</th>
                <td>{{ order.user.phone_number }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ order.user.email }}</td>
              </tr>
            </table>

            <h4 class="text-primary">Cinema Details</h4>
            <table class="table">
              <tr>
                <th>Name</th>
                <td>{{ order.cinema.name }}</td>
                <th>Address</th>
                <td>{{ order.cinema.address }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ order.cinema.email }}</td>
              </tr>
            </table>

            <h4 class="text-primary">Movie Details</h4>
            <table class="table">
              <tr>
                <th>Name</th>
                <td>{{ order.movie.name }}</td>
                <th>Service Fee</th>
                <td>{{ order.movie.service_charge | currency }}</td>
              </tr>
              <tr>
                <th>Preview Link</th>
                <td>
                  <a :href="order.movie.preview_link" target="_blank">{{ order.movie.preview_link }}</a>
                </td>
                <th>Cast</th>
                <td>{{ order.movie.cast }}</td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{{ order.movie.description }}</td>
                <th>Duration</th>
                <td>{{ order.movie.duration }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{ order.movie.sold_out? "Sold Out" : "Avilable" }}</td>
              </tr>
            </table>

            <div class="spinner-border" v-if="loading" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Movie Bookings</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Movies</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Orders</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchOrders()"
                        placeholder="search"
                        class="form-control"
                      />
                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div class="col-3">
                      <select v-model="orderStatus" @change="getOrders" class="form-control">
                        <option value="all">All Orders</option>
                        <option
                          v-for="(status,index) in allStatus"
                          :key="index"
                          :value="status"
                        >{{ status | capitalize }}</option>
                      </select>
                    </div>

                      <div class="col-3">
                      <select v-model="cinema" @change="getOrders" class="form-control">
                        <option value="all">All Cinema</option>
                        <option v-for="(cinema,index) in cinemas" :key="index" :value="cinema.id">{{ cinema.name }}</option>
                      </select>
                    </div>
                


                    <div class="col-3">
                      <select v-model="paymentStatus" @change="getOrders" class="form-control">
                        <option value="all">All Orders</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                      </select>
                    </div>
                  </div>

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Order No</th>
                        <th>Customer Name</th>
                        <th>Movie</th>
                        <th>Schedule</th>
                        <th>Phone Number</th>
                        <th>Payment status</th>
                        <th>Order status</th>
                        <th>Order Cost</th>

                        <th></th>
                      </tr>
                    </thead>
                    <span v-if="orders.length < 1">No order available</span>
                    <tbody v-else>
                      <tr v-for="(order,index) in orders" :key="index">
                        <td>{{ order.order_no }}</td>
                        <td>
                          <router-link v-if="order.user"
                            :to="'/profile/'+order.user.id  "
                          >{{ order.user.first_name | capitalize }} {{ order.user.last_name | capitalize }}</router-link>
                        </td>
                        <td>{{ order.movie.name }}</td>
                        <td>{{ order.schedule.time.day }} {{ order.schedule.time.date }} {{ order.schedule.time.time }}</td>
                        <td><span v-if="order.user" >{{ order.user.phone_number }}</span></td>
                        <td>{{ order.payment_status }}</td>
                        <td>{{ order.order_status  | capitalize }}</td>
                        <td>{{ order.total_cost | currency }}</td>
                        <td>
                          <button
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#viewMore"
                            @click="loadOrder(order,index)"
                          >More</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="orders.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      userId: this.$route.params.id,
      orders: [],
      interval: null,
      keyword: "",
      currentPage: 1,
      totalPages: 1,
      searching: false,
      paymentStatus: "all",
      orderStatus: "all",
      order: null,
      index: null,
      allStatus: [
        "pending",
        "confirmed",
        "rejected",
        "in-transit",
        "returned",
        "delivered"
      ],
      loading: false,
      riders: [],
      cinemas: [],
      cinema: "all"
    };
  },
  components: {},
  mounted() {
    this.getOrders();
    this.getCinemas();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      if (!this.searching) this.getOrders();
      else this.querySearch();
    },
    searchOrders() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getOrders();
        return;
      }

      console.log(encodeURIComponent(this.keyword));
      fetch(
        this.$store.getters.baseURL +
          "movies/bookings/admin/" +
          encodeURI(this.keyword) +
          "/search?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.orders = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    loadOrder(order, index) {
      this.order = { ...order };
      this.index = index;
    },
    payOrder() {
      this.loading = true;
      let link =
        this.$store.getters.baseURL +
        "movies/bookings/admin/" +
        this.order.id +
        "/pay";
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        method: "PUT"
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.orders.splice(this.index, 1, result.data);
            this.order.payment_status = result.data.payment_status;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loading = false;
        });
    },

    getCinemas() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + `movies/cinemas/all/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.cinemas = result.data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    updateStatus() {
      this.loading = true;
      let link =
        this.$store.getters.baseURL +
        "movies/bookings/admin/" +
        this.order.id +
        "/change_status";
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify({ status: this.order.order_status }),
        method: "PUT"
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.orders.splice(this.index, 1, result.data);
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loading = false;
        });
    },
    getOrders() {
      this.$store.commit("setLoading", true);
      let link =
        typeof this.userId == "undefined"
          ? this.$store.getters.baseURL +
            "movies/bookings/admin/" +
            this.orderStatus +
            "/" +
            this.paymentStatus +
            "/" + 
            this.cinema+
            "/all?page=" +
            this.currentPage
          : this.$store.getters.baseURL +
            "movies/bookings/admin/" +
            this.userId +
            "/all?page=" +
            this.currentPage;
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.orders = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>
</style>