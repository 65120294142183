<template>
    <div class="app-container">
      <!-- begin app-main -->
      <div class="app-main" id="main">
        <!-- begin container-fluid -->
        <div class="container-fluid">
          <!-- begin row -->
          <div class="row">
            <div class="col-md-12 m-b-30">
              <!-- begin page title -->
              <div class="d-block d-sm-flex flex-nowrap align-items-center">
                <div class="page-title mb-2 mb-sm-0">
                  <h1>Website Settlements</h1>
                </div>
                <div class="ml-auto d-flex align-items-center">
                  <nav>
                    <ol class="breadcrumb p-0 m-b-0">
                      <li class="breadcrumb-item">
                        <a>
                          <i class="ti ti-home"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item">Websites</li>
                      <li class="breadcrumb-item active text-primary" aria-current="page">
                        Settlements
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <!-- end page title -->
            </div>
          </div>
          <!-- end row -->
          <!-- begin row -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-statistics">
                <div class="card-body">
                  <Loader v-if="$store.getters.isLoading" />
                  <div v-else class="export-table-wrapper table-responsive">
                    <div class="export-buttons m-b-20">
                      <div class="col-3">
                        <input
                          type="text"
                          v-model="keyword"
                          @keyup="searchSettlements"
                          placeholder="Search Settlements"
                        />
                        <div
                          v-if="interval != null"
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <table id="export-table" class="table table-bordered">
                      <thead class="thead-light">
                        <tr>
                          <th>Reference ID</th>
                          <th>User Name</th>
                          <th>Phone Number</th>
                          <th>Website Name</th>
                          <th>Total Amount</th>
                          <th>Settled Amount</th>
                          <th>Status</th>
                          <th>Settlement Date</th>
                          <th>Transaction Date</th>
                        </tr>
                      </thead>
                      <span v-if="settlements?.length < 1">No settlements available</span>
                      <tbody v-else>
                        <tr v-for="(settlement, index) in settlements" :key="index">
                          <td>{{ settlement.id }}</td>
                          <td>
                            {{ settlement?.user?.first_name | capitalize }}
                            {{ settlement?.user?.last_name | capitalize }}
                          </td>
                          <td>{{ settlement?.user?.phone_number }}</td>
                          <td>{{ settlement?.website?.name }}</td>
                          <td>{{ settlement.total_amount | currency }}</td>
                          <td>{{ settlement.settled_amount | currency }}</td>
                          <td>{{ settlement.settled ? 'Settled' : 'Pending' }}</td>
                          <td>{{ settlement.settlement_date | formatDate }}</td>
                          <td>{{ settlement.transaction_date | formatDate }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <sliding-pagination
                      v-if="settlements?.length > 0"
                      :current="currentPage"
                      :total="totalPages"
                      @page-change="pageChangeHandler"
                    ></sliding-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end container-fluid -->
      </div>
      <!-- end app-main -->
    </div>
  </template>
  
  <script>
  import Swal from "sweetalert2";
  
  export default {
    data() {
      return {
        settlements: [],
        interval: null,
        keyword: "",
        currentPage: 1,
        totalPages: 1,
      };
    },
    components: {},
    mounted() {
      this.getSettlements();
    },
    methods: {
      pageChangeHandler(page) {
        this.currentPage = page;
        this.getSettlements();
      },
      getSettlements() {
        this.$store.commit("setLoading", true);
        const link = `${this.$store.getters.baseURL}websites/settlements/get_all?page=${this.currentPage}`;
        fetch(link, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.status) {
              this.settlements = result.data.data;
              this.currentPage = result.data.current_page;
              this.totalPages = result.data.last_page;
            } else {
              Swal.fire({
                icon: "error",
                title: result.message,
              });
            }
            this.$store.commit("setLoading", false);
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Check your connection",
            });
            this.$store.commit("setLoading", false);
          });
      },
      searchSettlements() {
        if (this.interval != null) {
          clearTimeout(this.interval);
          this.interval = null;
        }
        this.interval = setTimeout(this.querySearch, 2000);
      },
      querySearch() {
        clearTimeout(this.interval);
        this.interval = null;
        if (this.keyword.length < 1) {
          this.getSettlements();
          return;
        }
        const link = `${this.$store.getters.baseURL}websites/settlements/search/${encodeURIComponent(
          this.keyword
        )}`;
        fetch(link, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.status) {
              this.settlements = result.data;
              this.currentPage = 1;
              this.totalPages = 1;
            } else {
              Swal.fire({
                icon: "error",
                title: result.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Check your connection",
            });
          });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  