<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Wallet Transactions</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Wallet</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">
                      Transactions
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchTransactions()"
                        placeholder="search"
                      />
                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
               
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        
                        <th>Reference</th>
                          <th>Type</th>
                        <th>Customer Name</th>
                        <th>Customer Number</th>
                        <th>Wallet Balance</th>
                        <th>Previous Balance</th>
                        <th>Current Balance</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                     <span v-if="transactions.length < 1">No user Available</span>
                    <tbody v-else>
                      <tr v-for="(transaction,index) in transactions" :key="index">
                      
                        <td>{{ transaction?.reference }}</td>

                         <td>{{ transaction?.type }}</td>

                        <td>{{ transaction?.wallet?.user?.first_name | capitalize }} {{ transaction?.wallet?.user?.last_name | capitalize }}</td>
                       
                        <td>{{ transaction?.wallet?.user?.phone_number }}</td>

                        

                        <td>{{ transaction?.wallet?.balance | currency }}</td>                       
                        
                       <td>{{ transaction?.previous_balance | currency }}</td> 
                       
                       <td>{{ transaction?.current_balance | currency }}</td> 

                        <td>{{ transaction?.amount | currency }}</td> 
                       
                        <td>{{ transaction?.created_at | formatDate }}</td>
                      </tr>
                    </tbody>
                  </table>
                   <sliding-pagination
                   v-if="transactions?.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      userId: this.$route.params.id,
      transactions: [],
      interval: null,
      keyword: "",
      currentPage: 1,
      totalPages: 1,
    };
  },
  components: {

  },
  mounted() {
 
    this.getTransactions();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.getTransactions();
    },
    getTransactions() {
      
      this.$store.commit("setLoading", true);
      let link = (typeof this.userId == 'undefined')? this.$store.getters.baseURL + "wallet/transaction/all?page=" +
          this.currentPage : this.$store.getters.baseURL + "wallet/transaction/"+this.userId+"?page=" +
          this.currentPage;
      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {

            this.transactions = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    searchTransactions() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getTransactions();
        return;
      }
      let link = (typeof this.userId == 'undefined')? this.$store.getters.baseURL +
         'wallet/transaction/all/'+encodeURI(this.keyword)+'/search' : this.$store.getters.baseURL +
         'wallet/transaction/'+this.userId+'/'+encodeURI(this.keyword)+'/search';
      fetch(link,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.transactions = result.data;
            this.currentPage = 1;
            this.totalPages = 1;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
  }
};
</script>

<style scoped>
</style>