<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Menu Items</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/restaurant">Restaurants</router-link>
                    </li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">Menu Items</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Menu Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editMenuItem.name" />
                  </div>
                  <div class="form-group">
                    <label>Container Cost</label>
                    <input type="text" class="form-control" v-model="editMenuItem.container_cost" />
                  </div>
                  <div class="form-group">
                    <label>Cost</label>
                    <input type="text" class="form-control" v-model="editMenuItem.cost" />
                  </div>

                  <div class="form-group">
                    <label>Category</label>
                    <select class="form-control" v-model="editMenuItem.category_id">
                      <option>Select Category</option>
                      <option
                        :value="category.id"
                        v-for="(category,index) in categories"
                        :key="index"
                        :selected="(category.id == editMenuItem.category_id)? 'selected' : ''"
                      >{{category.name}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Restaurant</label>
                    <select class="form-control" v-model="editMenuItem.restaurant_id">
                      <option>Select Restaurant</option>
                      <option
                        :value="restaurant.id"
                        v-for="(restaurant,index) in restaurants"
                        :key="index"
                        :selected="(restaurant.id == editMenuItem.restaurant_id)? 'selected' : ''"
                      >{{restaurant.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="createMenuItem();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Menu Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editMenuItem.name" />
                  </div>
                  <div class="form-group">
                    <label>Container Cost</label>
                    <input type="text" class="form-control" v-model="editMenuItem.container_cost" />
                  </div>
                  <div class="form-group">
                    <label>Cost</label>
                    <input type="text" class="form-control" v-model="editMenuItem.cost" />
                  </div>

                  <div class="form-group">
                    <label>Category</label>
                    <select class="form-control" v-model="editMenuItem.category_id">
                      <option>Select Category</option>
                      <option
                        :value="category.id"
                        v-for="(category,index) in categories"
                        :key="index"
                        :selected="(category.id == editMenuItem.category_id)? 'selected' : ''"
                      >{{category.name}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Restaurant</label>
                    <select class="form-control" v-model="editMenuItem.restaurant_id">
                      <option>Select Restaurant</option>
                      <option
                        :value="restaurant.id"
                        v-for="(restaurant,index) in restaurants"
                        :key="index"
                        :selected="(restaurant.id == editMenuItem.restaurant_id)? 'selected' : ''"
                      >{{restaurant.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="updateMenuItem();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      @click.prevent="editMenuItem = {}"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Menu Items</a>
                    <div class="col-1"></div>
                    <select
                      v-model="restaurantId"
                      @change="getMenuItems()"
                      class="form-control col-2"
                    >
                      <option value="all">All Restaurants</option>
                      <option
                        v-for="(restaurant,index) in restaurants"
                        :key="index"
                        :value="restaurant.id"
                      >{{ restaurant.name | capitalize }}</option>
                    </select>

                    <div class="col-2"></div>
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchItems()"
                        placeholder="search"
                        class="form-control"
                      />
                    </div>
                    <div
                      v-if="interval != null"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Container Cost</th>
                        <th>Cost</th>
                        <th>Restaurant Name</th>
                        <th>Available</th>

                        <th colspan="3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="menuItems.length < 1">No Menu Item Found</span>
                      <tr v-for="(item,index) in menuItems" :key="index">
                        <td>
                          <img
                            :src="item.image"
                            class="profile-image"
                            style="width: 50px; height: 50px;"
                            alt="LOGO"
                          />
                        </td>
                        <td>{{item.name}}</td>
                        <td>{{item.category.name}}</td>
                        <td>{{item.container_cost | currency }}</td>
                        <td>{{item.cost | currency }}</td>
                        <td>{{item.restaurant != null ? item.restaurant.name : null }}</td>
                        <td>{{item.available? "Availble" : "Unavailabe" }}</td>
                        <td v-if="iLoading(index)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                        <td v-else>
                          <button
                            :class="{btn: true, 'btn-success': item.available, 'btn-danger': !item.available, 'mr-5':true}"
                            @click.prevent="toggleAvailability(item.id,index)"
                          >{{ (item.available)? 'Availble' : 'Finished' }}</button>
                        </td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(item)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleteMenuItem(item.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="menuItems.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      restaurants: [],
      menuItems: [],
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editMenuItem: {},
      restaurantId: "all",
      totalPages: 1,
      categories: [],
      interval: null,
      keyword: "",
      loadingBtn: []
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getMenuItems();
    this.getrestaurants();
    this.getStates();
    this.getCategories();
  },
  methods: {
    searchItems() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getMenuItems();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "restaurant/menu_items/search/" +
          encodeURI(this.keyword) +
          "?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.menuItems = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    pageChangeHandler(page) {
      this.currentPage = page;
      if (this.keyword.length < 1) this.getMenuItems();
      else this.querySearch();
    },
    encodeFile(e) {
      let file = e.target.files[0];
      if (!file) return;

      let reader = new FileReader();
      reader.onloadend = () => {
        this.editMenuItem.image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    prepEdit(item) {
      this.editMenuItem = { ...item };
      this.editMenuItem.image = null;
    },
    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    },

    toggleAvailability(id, index) {
      this.loadingBtn.push(index);
      fetch(
        this.$store.getters.baseURL +
          "restaurant/menu_items/" +
          id +
          "/toggle_availability",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.menuItems.splice(index, 1, result.data);
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(index), 1);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loadingBtn.splice(this.loadingBtn.indexOf(index), 1);
        });
    },
    getMenuItems() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "restaurant/menu_items/" +
          this.restaurantId +
          "/all?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.menuItems = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getCategories() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "restaurant/categories/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.categories = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getrestaurants() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "restaurant/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.restaurants = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    deleteMenuItem(id, index) {
      const link =
        this.$store.getters.baseURL +
        "restaurant/menu_items/" +
        id +
        "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete Item?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.menuItems.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },
    getStates() {
      fetch(this.$store.getters.baseURL + "states/admin/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.states = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    updateMenuItem() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "restaurant/menu_items/" +
          this.editMenuItem.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editMenuItem)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let itemIndex = this.menuItems.findIndex(item => {
              if (item.id == this.editMenuItem.id) return true;
            });
            this.menuItems[itemIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    createMenuItem() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "restaurant/menu_items/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.editMenuItem)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.menuItems = [result.data, ...this.menuItems];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped></style>
