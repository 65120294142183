<template>
  <div class="app-container">
    <div
      v-if="event != null"
      class="modal fade bd-example-modal-lg"
      id="viewTicket"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewMoreLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{event.name}} Tickets</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container mb-5">
              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" v-model="newTicket.name" />
              </div>
              <div class="form-group">
                <label>Cost</label>
                <input type="text" class="form-control" v-model="newTicket.cost" />
              </div>
              <button class="btn btn-primary" @click="addTicket()">Add</button>
            </div>
            <div class="container mb-5">
              <ol class="list-group">
                <li class="list-group-item" v-for="(ticket,index) in event.tickets" :key="index">
                  <div class="row">
                    <span class="test-dark col-3">{{ticket.name}}</span>
                    <span class="test-dark col-3">{{ticket.cost | currency}}</span>
                    <button class="btn btn-danger" @click="removeLocation(ticket.id,index)">remove</button>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Events</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a >
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Events</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="createModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create Event</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editEvent.name" />
                  </div>

                  <div class="form-group">
                    <label>Venue</label>
                    <input type="text" class="form-control" v-model="editEvent.venue" />
                  </div>

                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editEvent.phone_number" />
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="editEvent.description" />
                  </div>

                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="editEvent.email" />
                  </div>

                  <div class="form-group">
                    <label>Service Charge</label>
                    <input type="text" class="form-control" v-model="editEvent.service_charge" />
                  </div>

                  <div class="form-group">
                    <label>Link</label>
                    <input type="text" class="form-control" v-model="editEvent.url  " />
                  </div>

                  <div class="form-group">
                    <label>Date</label>
                    <input type="date" class="form-control" v-model="editEvent.date" />
                  </div>

                  <div class="form-group">
                    <label>Time</label>
                    <input type="time" class="form-control" v-model="editEvent.time" />
                  </div>

                  <div class="form-row">
                    <input type="checkbox" class="form-check mr-2" v-model="editEvent.free" />
                    <label class="form-check-label" for="exampleCheck1">Free</label>
                  </div>

                  <div class="form-group">
                    <label>Service</label>
                    <select class="form-control" v-model="editEvent.state_id">
                      <option>Select Service</option>
                      <option
                        :value="state.id"
                        v-for="(state,index) in states"
                        :key="index"
                        :selected="(state.id == editEvent.state_id)? 'selected' : ''"
                      >{{state.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="createEvent();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Service</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Image</label>
                    <input
                      type="file"
                      id="myFilemyFile"
                      @click="encodeFile"
                      @change="encodeFile"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editEvent.name" />
                  </div>

                  <div class="form-group">
                    <label>Venue</label>
                    <input type="text" class="form-control" v-model="editEvent.venue" />
                  </div>

                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" v-model="editEvent.phone_number" />
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="editEvent.description" />
                  </div>

                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" v-model="editEvent.email" />
                  </div>

                  <div class="form-group">
                    <label>Service Charge</label>
                    <input type="text" class="form-control" v-model="editEvent.service_charge" />
                  </div>

                  <div class="form-group">
                    <label>Link</label>
                    <input type="text" class="form-control" v-model="editEvent.url  " />
                  </div>

                  <div class="form-group">
                    <label>Date</label>
                    <input type="date" class="form-control" v-model="editEvent.date" />
                  </div>

                  <div class="form-group">
                    <label>Time</label>
                    <input type="time" class="form-control" v-model="editEvent.time" />
                  </div>

                  <div class="form-row">
                    <input type="checkbox" class="form-check mr-2" v-model="editEvent.free" />
                    <label class="form-check-label" for="exampleCheck1">Free</label>
                  </div>

                  <div class="form-group">
                    <label>Service</label>
                    <select class="form-control" v-model="editEvent.state_id">
                      <option>Select Service</option>
                      <option
                        :value="state.id"
                        v-for="(state,index) in states"
                        :key="index"
                        :selected="(state.id == editEvent.state_id)? 'selected' : ''"
                      >{{state.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="updateEvent();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      @click.prevent="editEvent = {}"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Event</a>
                    <div class="col-1"></div>
                    <select v-model="stateId" @change="getEvents()" class="form-control col-2">
                      <option value="all">All Services</option>
                      <option
                        v-for="(state,index) in states"
                        :key="index"
                        :value="state.id"
                      >{{ state.name | capitalize }}</option>
                    </select>

                    <div class="col-1"></div>
                    <select v-model="free" @change="getEvents()" class="form-control col-2">
                      <option value="all">All Type</option>
                      <option value="1">Free</option>
                      <option value="0">Paid</option>
                    </select>

                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        @keyup="searchEvents()"
                        placeholder="search"
                        class="form-control"
                      />
                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>

                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>State</th>
                        <th>Type</th>
                        <th>Sold Out</th>

                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="states.length < 1">No Service Found</span>
                      <tr v-for="(event,index) in events" :key="index">
                        <td>
                          <img
                            :src="event.image"
                            class="profile-image"
                            style="width: 50px; height: 50px;"
                            alt="LOGO"
                          />
                        </td>
                        <td>{{event.name}}</td>
                        <td>{{event.email}}</td>
                        <td>{{event.state.name }}</td>
                        <td>{{event.free? "Free" : "Paid" }}</td>
                        <td>{{event.sold_out? "Yes" : "No"}}</td>

                        <td>
                          <button
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#viewTicket"
                            @click="loadOrder(event,index)"
                            :disabled="(event.free)? true : false"
                          >View Tickets</button>
                        </td>
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(event)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>

                        <td>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleteEvent(event.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="events.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editEvent: {},
      events: [],
      totalPages: 1,
      keyword: "",
      event: {},
      index: null,
      selectedLocation: "",
      interval: null,
      stateId: "all",
      free: "all",
      newTicket: {}
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getStates();
    this.getEvents();
  },
  methods: {
    searchEvents() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },
    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getOrders();
        return;
      }

      fetch(
        this.$store.getters.baseURL +
          "events/events/" +
          encodeURI(this.keyword) +
          "/search?page=" +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.events = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },
    loadOrder(event, index) {
      this.event = { ...event };
      this.index = index;
    },
    pageChangeHandler(page) {
      this.currentPage = page;
      if (this.keyword.length < 1) this.getEvents();
      else this.querySearch();
    },
    encodeFile(e) {
      let file = e.target.files[0];
      if (!file) return;

      let reader = new FileReader();
      reader.onloadend = () => {
        this.editEvent.image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    prepEdit(state) {
      console.log(state);
      this.editEvent = { ...state };
      this.editEvent.image = null;
      this.editEvent.date = state.time.date;
      this.editEvent.time = state.time.time;

      console.log(this.editEvent);
    },
    getStates() {
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "states/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.states = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getEvents() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          `events/events/${this.stateId}/${this.free}/all?page=` +
          this.currentPage,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.events = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    deleteEvent(id, index) {
      const link =
        this.$store.getters.baseURL + "events/events/" + id + "/destroy";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete event?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.events.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },

    updateEvent() {
      this.editEvent.free = Number(this.editEvent.free);
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "events/events/" +
          this.editEvent.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editEvent)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let artisanIndex = this.events.findIndex(event => {
              if (event.id == this.editEvent.id) return true;
            });
            this.events[artisanIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    createEvent() {
      this.editEvent.free = Number(this.editEvent.free);
      this.$store.commit("setLoading", true);
      fetch(this.$store.getters.baseURL + "events/events/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(this.editEvent)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.events = [result.data, ...this.events];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    getUnassignedLocations(id) {
      console.log(this.event);
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + `states/events/${id}/unassigned_location`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          method: "PUT"
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.unassignedLocation = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    addTicket() {
      this.$store.commit("setLoading", true);

      fetch(
        this.$store.getters.baseURL +
          `events/events/${this.event.id}/add_ticket`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          method: "PUT",
          body: JSON.stringify(this.newTicket)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.event.tickets = [result.data, ...this.event.tickets];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    removeLocation(locationId, index) {
      this.$store.commit("setLoading", true);

      fetch(
        this.$store.getters.baseURL +
          `events/events/${this.event.id}/remove_ticket`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          method: "PUT",
          body: JSON.stringify({ ticket_id: locationId })
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.event.tickets.splice(index, 1);
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped></style>
