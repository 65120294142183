<template>
  <!-- begin app-wrap -->
  <div class="app-wrap">
    <!--start login contant-->
    <div class="app-contant">
      <div class="bg-white">
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col-sm-6 col-lg-5 col-xxl-3 align-self-center order-2 order-sm-1">
              <div class="d-flex align-items-center h-100-vh">
                <div class="register p-5">
                  <h1 class="mb-2">We are Mentor</h1>
                  <p>Welcome, Please create your account.</p>
                  <form class="mt-2 mt-sm-5">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="control-label">First Name*</label>
                          <input type="text" class="form-control" placeholder="First Name" />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="control-label">Last Name*</label>
                          <input type="text" class="form-control" placeholder="Last Name" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">Email*</label>
                          <input type="email" class="form-control" placeholder="Email" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">Username*</label>
                          <input type="text" class="form-control" placeholder="Username" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">Password*</label>
                          <input type="password" class="form-control" placeholder="Password" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="gridCheck" />
                          <label class="form-check-label" for="gridCheck">I accept terms & policy</label>
                        </div>
                      </div>
                      <div class="col-12 mt-3">
                        <a class="btn btn-primary text-uppercase">Sign up</a>
                      </div>
                      <div class="col-12 mt-3">
                        <p>
                          Already have an account ?
                          <a>Sign In</a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-9 col-lg-7 bg-gradient o-hidden order-1 order-sm-2">
              <div class="row align-items-center h-100">
                <div class="col-7 mx-auto">
                  <img class="img-fluid" src="assets/img/bg/login.svg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end app-wrap -->
</template>

<script>
export default {};
</script>

<style scoped>
</style>