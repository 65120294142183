<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Locations</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Locations</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>

         <div
          class="modal fade"
          id="createModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Creete Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editLocation.name" />
                  </div>
                   <div class="form-group">
                    <label>State</label>
                    <select
                      class="form-control"
                      v-model="editLocation.state_id"
                      @change.prevent="getLocations()"
                    >
                      <option v-for="(state,index) in states" :key="index" :value="state.id">{{state.name}}</option>
                    </select>
                     </div>
                 
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="createLocation();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editLocation.name" />
                  </div>
                 
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="updateLocation();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <a
                      href="#"
                      id="exportExcel"
                      class="btn btn-primary col-2"
                      @click.prevent="editLocation = {}"
                      data-toggle="modal"
                      data-target="#createModal"
                    >Create Location</a>
                    <div class="col-1"></div>
                    <select
                      class="form-control col-2"
                      v-model="state_id"
                      @change.prevent="getLocations()"
                    >
                     
                      <option v-for="(state,index) in states" :key="index" :value="state.id">{{state.name}}</option>
                    </select>

                    <div class="col-2"></div>

                   
                    <div
                      v-if="interval != null"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th width="80%">Name</th>
                        <th colspan="2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="locations.length < 1">No Bank Found</span>
                      <tr v-for="(location,index) in locations" :key="index">
                        <td>{{location.name}}</td>

                        <td v-if="iLoading(location.id)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                       
                        <td>
                          <button
                            class="btn btn-secondary mr-5"
                            @click.prevent="editing = true; prepEdit(location)"
                            data-toggle="modal"
                            data-target="#editModal"
                          >&#9998;</button>
                        </td>

                         <td>
                          <button
                            class="btn btn-danger"
                            @click.prevent="deleteLocation(location.id,index)"
                          >🗑</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <sliding-pagination
                    v-if="locations.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
      locations: [],
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editLocation: {},
      state_id: 1,
      loadingBtn: [],
      totalPages: 1,
      interval: null,
      keyword: ""
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getLocations();
    this.getstates();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;

      this.getLocations();
    },
    prepEdit(location) {
      this.editLocation = { ...location };
    },
    getLocations() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + "states/"+this.state_id+"/locations",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.locations = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    toggleStatus(id, index) {
      this.loadingBtn.push(id);
      const link =
        this.$store.getters.baseURL + "location/list/" + id + "/toggle_active";

      fetch(link, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.locations[index] = result.data;
            this.$forceUpdate();
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loadingBtn.splice(this.loadingBtn.indexOf(id), 1);
        });
    },

    updateLocation() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + "states/admin/"+this.editLocation.id+"/update_location",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editLocation)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let locationIndex = this.locations.findIndex(location => {
              if (location.id == this.editLocation.id) return true;
            });
            this.locations[locationIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },


    createLocation() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL + "states/admin/create_location",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editLocation)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            if(this.editLocation.state_id == this.state_id) this.locations = [result.data, ...this.locations];
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },


    getstates() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "states/admin/all",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          
          
          if (result.status) {
            this.states = result.data;
          
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }

          
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    searchBanks() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },

    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getLocations();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "location/list/" +
          encodeURI(this.keyword) +
          "/search",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.locations = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },

    deleteLocation(id, index) {
      const link =
        this.$store.getters.baseURL + "states/admin/"+id+"/delete_location";

      Swal.queue([
        {
          icon: "warning",
          title: "Delete Location?",
          confirmButtonText: "Confirm Delete!",
          showCancelButton: true,
          text: "This action is irreversible and can break the system",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(link, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.$store.getters.getToken
              }
            })
              .then(response => response.json())
              .then(result => {
                if (result.status) {
                  Swal.insertQueueStep({
                    icon: "success",
                    title: result.message
                  });
                  this.locations.splice(index, 1);
                } else {
                  Swal.insertQueueStep({
                    icon: "error",
                    title: result.message
                  });
                }
              })
              .catch(() => {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Some thing went wrong",
                  text: "Check your internet connection"
                });
              });
          }
        }
      ]);
    },

    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    }
  }
};
</script>

<style scoped></style>
