<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>Tasks</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">Task</li>
                    <li class="breadcrumb-item active text-primary" aria-current="page">
                      Tasks
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>
        <!-- end row -->
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="row export-buttons m-b-20">
                    <div class="col-3">
                      <input
                        type="text"
                        v-model="keyword"
                        placeholder="search"
                        class="form-control"
                      />
                    </div>

                    <div class="col-3">
                      <select
                        v-model="status"
                        class="form-control"
                      >
                        <option value="ALL">Status</option>
                        <option value="INACTIVE">INACTIVE</option>
                        <option value="ACTIVE">ACTIVE</option>
                      </select>
                    </div>

                    <div class="col-3">
                      <select
                        v-model="approvalStatus"
                        class="form-control"
                      >
                        <option value="ALL">Approval Status</option>
                        <option value="PENDING">PENDING</option>
                        <option value="APPROVED">APPROVED</option>
                      </select>
                    </div>

                    <div class="col-3">
                      
                      <div>
                        <button class="btn btn-success" @click="getTransactions()">Filter</button>
                      </div>

                      <div
                        v-if="interval != null"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
               
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        
                        <th></th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>User</th>
                        <th>Admin Note</th>
                        <th>Amount Per Task</th>
                        <th>Approval Status</th>
                       
                      
                        <th>Execution time(seconds)</th>
                        <th>Max Task</th>
                        <th>Total SubTask</th>
                        <th>Max Task Per user</th>
                        
                        <th>Staked Amount</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                     <span v-if="tasks?.length < 1">No Task</span>
                    <tbody v-else>
                      <tr v-for="(task,index) in tasks" :key="index">

                          <td>{{ task?.id }}</td>
                          <td>{{ task?.name }}</td>
                          <td v-html="task?.description"></td>
                          <td>{{ task?.owner_user_id }}</td>
                          <td>{{ task?.admin_note }}</td>
                          <td>{{ task?.amount_per_task | currency }}</td>
                          <td>{{ task?.approval_status }}</td>
                         
                         
                          <td>{{ task?.execution_time_in_seconds }}</td>
                          <td>{{ task?.max_task }}</td>
                          <td>{{ task?.sub_tasks.length }}</td>
                          <td>{{ task?.max_task_per_user }}</td>
                          
                          <td>{{ task?.staked_amount | currency }}</td>
                          <td>{{ task?.status }}</td>
                          <td>{{ task?.created_at }}</td>
                          <td>{{ task?.deleted_at }}</td>
                          <td>
                            <div>
                              <router-link class="btn btn-info" to="/tasks/subtask"> Tasks</router-link >
                            </div>
                            
                          </td>
                          <td>
                            <div v-if="task?.approval_status == 'APPROVED'">
                              <textarea v-model="task.reason" class="form-control"></textarea>
                               <button @click="updateStatus(task.id, 'REJECTED', task?.reason)" class="btn btn-success">Reject</button>
                            </div>
                            <button @click="updateStatus(task.id, 'APPROVED', '')" v-else class="btn btn-success">Approve</button>

                          </td>
                      </tr>
                    </tbody>
                  </table>
                   <sliding-pagination
                   v-if="tasks?.length > 0"
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  ></sliding-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { RouterLink } from "vue-router";

export default {
  data() {
    return {
      userId: this.$route.params.userId,
      tasks: [],
      interval: null,
      keyword: "",
      currentPage: 1,
      totalPages: 1,
      status: "ALL",
      approvalStatus: "ALL",
      reason: ""
    };
  },
  components: {
    RouterLink
},

  mounted() {
 
    this.getTransactions();
  },
  methods: {
   
    pageChangeHandler(page) {
      this.currentPage = page;
      console.log(page);
      this.getTransactions();
    },
   
   
    getTransactions() {
      
      this.$store.commit("setLoading", true);
      let link = this.$store.getters.baseURL + "task/admin/getTask?&page=" +this.currentPage + 
      ((this.status != "ALL")? "&status="+this.status : "") +
      ((this.approvalStatus != "ALL")? "&approval_status="+this.approvalStatus : "") +
      ((this.keyword.length > 0 )? "&name="+this.keyword : "") +
      ((this.userId != undefined)? "&user_id="+this.userId : "")
      
      ;

      fetch(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {

            this.tasks = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    updateStatus(id, status, note) {
      
      this.$store.commit("setLoading", true);
      let link = this.$store.getters.baseURL + "task/admin/updateTaskStatus";

      let data = {
        task_id: id,
        status: status,
        admin_note: note
      };

      fetch(link, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.getTransactions();
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    
  
  }
};
</script>

<style scoped>
</style>