<template>
  <div class="app-container">
    <!-- begin app-main -->
    <div class="app-main" id="main">
      <!-- begin container-fluid -->
      <div class="container-fluid">
        <!-- begin row -->
        <div class="row">
          <div class="col-md-12 m-b-30">
            <!-- begin page title -->
            <div class="d-block d-sm-flex flex-nowrap align-items-center">
              <div class="page-title mb-2 mb-sm-0">
                <h1>states</h1>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <nav>
                  <ol class="breadcrumb p-0 m-b-0">
                    <li class="breadcrumb-item">
                      <a>
                        <i class="ti ti-home"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item">states</li>
                  </ol>
                </nav>
              </div>
            </div>
            <!-- end page title -->
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit state</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="editstate.name" />
                  </div>
               
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  type="button"
                  @click="updatestate();"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row">
          <div class="col-lg-12">
            <Loader v-if="$store.getters.isLoading" />
            <div v-else class="card card-statistics">
              <div class="card-body">
                <Loader v-if="$store.getters.isLoading" />
                <div v-else class="export-table-wrapper table-responsive">
                  <div class="export-buttons m-b-20 row">
                    <div class="col-1"></div>
                   

                    <div class="col-2"></div>

                  </div>
                  <table id="export-table" class="table table-bordered">
                    <thead class="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <span v-if="states.length < 1">No state Found</span>
                      <tr v-for="(state,index) in states" :key="index">
                        <td>{{state.name}}</td>
                        <td>{{state.active? 'active' : 'inactive'}}</td>

                        <td v-if="iLoading(state.id)">
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                        <td v-else>
                          <button
                            class="btn btn-danger"
                            @click.prevent="toggleStatus(state,index)"
                          >
                            <template v-if="!state.active">Activate</template>
                            <template v-else>Deactivate</template>
                          </button>
                        </td>
                       
                      </tr>
                    </tbody>
                  </table>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end app-main -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "../../components/Loader.vue";
export default {
  data() {
    return {
  
      states: [],
      editing: false,
      state: "all",
      currentPage: 1,
      editstate: {},
      status: "all",
      loadingBtn: [],
      totalPages: 1,
      interval: null,
      keyword: ""
    };
  },
  components: {
    Loader
  },
  mounted() {
    this.getstates();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;

      this.getstates();
    },
    prepEdit(state) {
      this.editstate = { ...state };
      this.editstate.image = null;
    },
    getstates() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "states/admin/all",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          
          
          if (result.status) {
            this.states = result.data;
          
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }

          
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },
    toggleStatus(state, index) {
      this.loadingBtn.push(state.id);
      const link =
        this.$store.getters.baseURL + "states/admin/" + state.id + "/update";

      fetch(link, {
        method: "PUT",
        body: JSON.stringify({"active": (!state.active * 1 / 1) }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.getters.getToken
        }
      })
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.states[index] = result.data;
            this.$forceUpdate();
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.loadingBtn.splice(this.loadingBtn.indexOf(state.id), 1);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.loadingBtn.splice(this.loadingBtn.indexOf(state.id), 1);
        });
    },

    updatestate() {
      this.$store.commit("setLoading", true);
      fetch(
        this.$store.getters.baseURL +
          "state/list/" +
          this.editstate.id +
          "/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          },
          body: JSON.stringify(this.editstate)
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            let stateIndex = this.states.findIndex(state => {
              if (state.id == this.editstate.id) return true;
            });
            this.states[stateIndex] = result.data;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    },

    searchstates() {
      if (this.interval != null) {
        clearTimeout(this.interval);
        this.interval = null;
      }
      this.interval = setTimeout(this.querySearch, 2000);
    },

    querySearch() {
      clearTimeout(this.interval);
      this.interval = null;
      if (this.keyword.length < 1) {
        this.getstates();
        return;
      }
      fetch(
        this.$store.getters.baseURL +
          "state/list/" +
          encodeURI(this.keyword) +
          "/search",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          if (result.status) {
            this.states = result.data.data;
            this.currentPage = result.data.current_page;
            this.totalPages = result.data.last_page;
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
        });
    },

    iLoading(id) {
      if (this.loadingBtn.includes(id)) return true;
      return false;
    }
  }
};
</script>

<style scoped></style>
